<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "planeIcon",
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M6.84981 17.1508L3.6498 15.4008L4.6998 14.3508L7.19981 14.7008L11.0998 10.8008L3.2998 6.55078L4.6998 5.15078L14.2498 7.60078L18.1748 3.72578C18.4581 3.44245 18.8123 3.30078 19.2373 3.30078C19.6623 3.30078 20.0165 3.44245 20.2998 3.72578C20.5831 4.00911 20.7248 4.36328 20.7248 4.78828C20.7248 5.21328 20.5831 5.56745 20.2998 5.85078L16.3998 9.75078L18.8498 19.3008L17.4498 20.7008L13.1998 12.9008L9.29981 16.8008L9.64981 19.3008L8.59981 20.3508L6.84981 17.1508Z"
      stroke-width="1.57"
    />
  </svg>
</template>

<style scoped>
svg {
  stroke: currentColor !important;
}
</style>
