import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./sentry";
import "vuetify/styles";
import "@/styles/main.css";
import "@/styles/tailwind.css";
import "@/services/_globals";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import createCookies from "vue3-cookies";
import { isLoggedIn, logoutUser } from "@/services/auth";

const app = createApp(App);

const components = {
  "app-alert": () => import("@/components/shared/AppAlert.vue"),
  "app-input-field": () => import("@/components/shared/AppInputField.vue"),
  "app-date-field": () => import("@/components/shared/AppDateFields.vue"),
  "app-primary-button": () =>
    import("@/components/shared/AppPrimaryButton.vue"),
  "app-secondary-button": () =>
    import("@/components/shared/AppSecondaryButton.vue"),
  "app-select-field": () => import("@/components/shared/AppSelectField.vue"),
  "app-search-field": () => import("@/components/shared/AppSearchField.vue"),
  "app-header": () => import("@/components/shared/AppHeader.vue"),
  "app-select-field-object": () =>
    import("@/components/shared/AppSelectFieldWithObject.vue"),
  "app-access-role-sections": () =>
    import("@/components/shared/AccessRoleSections.vue"),
  "app-multi-select": () => import("@/components/shared/AppMultiSelect.vue"),
  "app-single-select": () => import("@/components/shared/AppSingleSelect.vue"),
  "app-single-select-id": () =>
    import("@/components/shared/AppSinglewithId.vue"),
  "app-accordion": () => import("@/components/shared/AppAccordion.vue"),
  "app-file-upload": () => import("@/components/shared/AppFileUpload.vue"),
  "app-toggle-button": () => import("@/components/shared/AppToggleButton.vue"),
  "app-text-editor": () => import("@/components/shared/AppTextEditor.vue"),
  "app-prompt-alert": () => import("@/components/shared/AppPromptAlert.vue"),
  "app-text-area": () => import("@/components/shared/AppTextArea.vue"),
  "app-loader": () => import("@/components/shared/AppLoader.vue"),
  "app-unsaved-changes-dialog": () =>
    import("@/components/shared/AppUnsavedChangesDialog.vue"),
  "multi-select": () => import("@/components/shared/MultiSelect.vue"),
  "app-tooltip": () => import("@/components/shared/AppTooltip.vue"),
  "app-basic-table": () => import("@/components/shared/AppBasicTable.vue"),
  "app-custom-pagination": () =>
    import("@/components/shared/AppCustomPagination.vue"),
  "app-radio-button": () => import("@/components/shared/AppRadio.vue"),
  "app-year-select": () => import("@/components/shared/AppYearSelect.vue"),
  "app-month-select": () => import("@/components/shared/AppMonthSelect.vue"),
  "app-date-picker": () => import("@/components/shared/AppDatePicker.vue"),
  "custom-date-picker": () =>
    import("@/components/shared/CustomDatePicker.vue"),
  "app-signature-dialog": () =>
    import("@/components/shared/SignatureDialog.vue"),
  "app-notification": () => import("@/components/shared/AppNotification.vue"),
  "app-upload-signature": () =>
    import("@/components/shared/UploadSignature.vue"),
  "app-upload-documents": () =>
    import("@/components/shared/UploadDocuments.vue"),
  "app-invoice-file-upload": () =>
    import("@/components/shared/InvoiceFileUpload.vue"),
  "app-custom-table": () => import("@/components/shared/AppCustomTable.vue"),
  "filter-by-company-and-business-area": () =>
    import("@/components/shared/FilterByCompanyAndBusinessArea.vue"),
  "app-cancel-draft-save-button": () =>
    import("@/components/shared/CancelDraftSaveButton.vue"),
  "app-edit-component": () =>
    import("@/components/shared/AppEditComponent.vue"),
  "app-view-request-details": () =>
    import("@/components/shared/ViewRequestDetails.vue"),
  "exchange-rate-component": () =>
    import(
      "@/components/dashboard/accounting/incomingInvoices/ExchangeRateComponent.vue"
    ),
  "app-phone-input": () => import("@/components/shared/AppPhoneInput.vue"),
  "app-custom-tab": () => import("@/components/shared/AppCustomTab.vue"),
  "app-new-tabs": () => import("@/components/shared/AppNewTabs.vue"),
  "app-view-file": () => import("@/components/shared/ViewFile.vue"),
  "app-upload-picture": () =>
    import("@/components/shared/AppUploadPicture.vue"),
  "app-drop-down-info": () => import("@/components/shared/DropDownInfo.vue"),
  "app-table": () => import("@/components/shared/AppTable.vue"),
  "app-checkbox": () => import("@/components/shared/AppCheckBox.vue"),
  "app-dialog": () => import("@/components/shared/AppDialog.vue"),
  "app-document-upload": () =>
    import("@/components/shared/AppDocumentUpload.vue"),
  "app-access-denied": () =>
    import("@/components/authentication/AccessDenied.vue"),
  "base-invoice-table": () =>
    import("@/components/shared/BaseInvoiceTable.vue"),
  "base-invoice-settlement": () =>
    import("@/components/shared/BaseInvoiceSettlement.vue"),
  "base-bank-details": () =>
    import("@/components/shared/BaseBankDetails.vue"),
  "app-month-year-picker": () => import("@/components/shared/MonthYearPicker.vue"),
};

Object.entries(components).forEach(([name, component]: any) => {
  app.component(name, defineAsyncComponent(component));
});

app
  .use(router)
  .use(createCookies)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(ToastPlugin, { position: "bottom-right" })
  .mount("#app");

let lastActivity = Date.now();
const inactivityTimeout = 30 * 60 * 1000;

function checkInactivity() {
  const currentTime = Date.now();
  if (isLoggedIn() && currentTime - lastActivity > inactivityTimeout) {
    router.push("/").then(() => {
      logoutUser();
    });
  }
}

document.addEventListener("mousemove", () => (lastActivity = Date.now()));
document.addEventListener("keypress", () => (lastActivity = Date.now()));
document.addEventListener("touchmove", () => (lastActivity = Date.now()));
document.addEventListener("touchstart", () => (lastActivity = Date.now()));
document.addEventListener("touchmove", () => (lastActivity = Date.now()));
document.addEventListener("tap", () => (lastActivity = Date.now()));
document.addEventListener("doubletap", () => (lastActivity = Date.now()));
document.addEventListener("swipe", () => (lastActivity = Date.now()));

setInterval(checkInactivity, 1000);
