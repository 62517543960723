<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NewEmptyIcon",
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="160"
    height="120"
    viewBox="0 0 160 120"
    fill="none"
  >
    <circle cx="81.5" cy="52" r="52" fill="#EAECF0" />
    <g filter="url(#filter0_dd_26029_119931)">
      <path
        d="M47.2266 79.1088L80.4651 63.6094C82.1339 62.8313 82.8559 60.8476 82.0777 59.1787L62.4484 17.0835L49.1563 12.2456L21.9611 24.9269C20.2923 25.7051 19.5703 27.6888 20.3485 29.3576L42.7959 77.4962C43.574 79.165 45.5577 79.887 47.2266 79.1088Z"
        fill="url(#paint0_linear_26029_119931)"
      />
      <path
        d="M49.1562 12.2461L62.4484 17.084L53.3834 21.3111L49.1562 12.2461Z"
        fill="#D0D5DD"
      />
    </g>
    <g filter="url(#filter1_dd_26029_119931)">
      <path
        d="M63.1153 67.7831H99.79C101.631 67.7831 103.124 66.2904 103.124 64.4491V18.0022L93.1218 8H63.1153C61.274 8 59.7812 9.49271 59.7812 11.3341V64.4491C59.7812 66.2904 61.274 67.7831 63.1153 67.7831Z"
        fill="url(#paint1_linear_26029_119931)"
      />
      <path d="M93.1211 8L103.123 18.0022H93.1211V8Z" fill="#D0D5DD" />
    </g>
    <g filter="url(#filter2_dd_26029_119931)">
      <path
        d="M81.975 63.5909L115.214 79.0903C116.882 79.8685 118.866 79.1465 119.644 77.4777L139.274 35.3825L134.436 22.0903L107.24 9.40903C105.572 8.63085 103.588 9.35286 102.81 11.0217L80.3623 59.1602C79.5842 60.8291 80.3062 62.8128 81.975 63.5909Z"
        fill="url(#paint2_linear_26029_119931)"
      />
      <path
        d="M134.436 22.0898L139.273 35.382L130.208 31.1549L134.436 22.0898Z"
        fill="#D0D5DD"
      />
    </g>
    <circle cx="26.5" cy="11" r="5" fill="#F2F4F7" />
    <circle cx="23.5" cy="109" r="7" fill="#F2F4F7" />
    <circle cx="150.5" cy="35" r="7" fill="#F2F4F7" />
    <circle cx="139.5" cy="8" r="4" fill="#F2F4F7" />
    <g filter="url(#filter3_b_26029_119931)">
      <rect
        x="57.5"
        y="62"
        width="48"
        height="48"
        rx="24"
        fill="#344054"
        fill-opacity="0.4"
      />
      <path
        d="M91.5 93C91.5 93.5304 91.2893 94.0391 90.9142 94.4142C90.5391 94.7893 90.0304 95 89.5 95H73.5C72.9696 95 72.4609 94.7893 72.0858 94.4142C71.7107 94.0391 71.5 93.5304 71.5 93V79C71.5 78.4696 71.7107 77.9609 72.0858 77.5858C72.4609 77.2107 72.9696 77 73.5 77H78.5L80.5 80H89.5C90.0304 80 90.5391 80.2107 90.9142 80.5858C91.2893 80.9609 91.5 81.4696 91.5 82V93Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_26029_119931"
        x="-1.06055"
        y="8.01953"
        width="104.547"
        height="112.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_26029_119931"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_26029_119931"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_26029_119931"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_26029_119931"
          result="effect2_dropShadow_26029_119931"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_26029_119931"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_26029_119931"
        x="39.7812"
        y="8"
        width="83.3418"
        height="99.7812"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_26029_119931"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_26029_119931"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_26029_119931"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_26029_119931"
          result="effect2_dropShadow_26029_119931"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_26029_119931"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dd_26029_119931"
        x="58.9531"
        y="8"
        width="104.547"
        height="112.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_26029_119931"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_26029_119931"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_26029_119931"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_26029_119931"
          result="effect2_dropShadow_26029_119931"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_26029_119931"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_b_26029_119931"
        x="49.5"
        y="54"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_26029_119931"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_26029_119931"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_26029_119931"
        x1="45.2738"
        y1="79.2386"
        x2="18.7853"
        y2="31.4513"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E4E7EC" />
        <stop offset="1" stop-color="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_26029_119931"
        x1="61.2907"
        y1="67.0755"
        x2="57.4797"
        y2="12.571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E4E7EC" />
        <stop offset="1" stop-color="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_26029_119931"
        x1="80.6204"
        y1="62.1785"
        x2="100.201"
        y2="11.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E4E7EC" />
        <stop offset="1" stop-color="#F9FAFB" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped></style>
