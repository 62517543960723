<template>
  <div
    :class="[
      'w-screen h-screen overflow-y-auto relative',
      isAuth ? '' : 'bg-gray-50',
    ]"
  >
    <div v-if="isAuth" class="absolute inset-0 -z-10">
      <img
        class="w-full h-full object-cover"
        :src="background"
        alt="ICON house"
      />
      <div class="absolute inset-0 bg-[#8B2923] opacity-30"></div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import background from "@/assets/Login.webp";
export default {
  name: "BlankLayout",
  data() {
    return {
      background,
    };
  },
  computed: {
    isAuth() {
      return (
        this.$route.name === "login" ||
        this.$route.name === "forgot-password" ||
        this.$route.name === "reset-password"
      );
    },
  },
  methods: {},
};
</script>

<style scoped>
:deep(.form) {
  max-width: 440px;
  padding: 2rem 1.5rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}
:deep(form) {
  width: 100%;
  margin-top: 1.5rem;
}
:deep(.flex-container) {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
</style>
