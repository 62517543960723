<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LinkedInIcon",
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <rect
      x="2.10742"
      y="2"
      width="17.5"
      height="17.5"
      rx="8.75"
      fill="#1275B1"
    />
    <path
      d="M8.74402 6.80759C8.74402 7.39169 8.23774 7.86519 7.61322 7.86519C6.9887 7.86519 6.48242 7.39169 6.48242 6.80759C6.48242 6.2235 6.9887 5.75 7.61322 5.75C8.23774 5.75 8.74402 6.2235 8.74402 6.80759Z"
      fill="white"
    />
    <path d="M6.63706 8.64256H8.57005V14.5H6.63706V8.64256Z" fill="white" />
    <path
      d="M11.6822 8.64256H9.74917V14.5H11.6822C11.6822 14.5 11.6822 12.656 11.6822 11.503C11.6822 10.811 11.9185 10.116 12.8613 10.116C13.9268 10.116 13.9204 11.0216 13.9154 11.7232C13.9089 12.6403 13.9244 13.5762 13.9244 14.5H15.8574V11.4086C15.8411 9.43461 15.3267 8.52505 13.6345 8.52505C12.6295 8.52505 12.0066 8.98129 11.6822 9.39406V8.64256Z"
      fill="white"
    />
  </svg>
</template>

<style scoped></style>
