<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NoticeCheck",
});
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
  >
    <path
      d="M7.625 0.320312C6.28998 0.320312 4.98494 0.716193 3.8749 1.45789C2.76487 2.19959 1.89971 3.2538 1.38882 4.4872C0.877925 5.7206 0.744252 7.0778 1.0047 8.38717C1.26515 9.69654 1.90803 10.8993 2.85203 11.8433C3.79604 12.7873 4.99877 13.4302 6.30814 13.6906C7.61751 13.9511 8.97471 13.8174 10.2081 13.3065C11.4415 12.7956 12.4957 11.9304 13.2374 10.8204C13.9791 9.71038 14.375 8.40533 14.375 7.07031C14.373 5.2807 13.6612 3.56496 12.3958 2.29951C11.1304 1.03407 9.41461 0.322278 7.625 0.320312ZM10.1272 6.19754L7.42723 8.89754C7.30065 9.02408 7.12899 9.09517 6.95 9.09517C6.77102 9.09517 6.59936 9.02408 6.47278 8.89754L5.12278 7.54754C4.99982 7.42023 4.93178 7.24972 4.93332 7.07274C4.93486 6.89576 5.00585 6.72646 5.131 6.60131C5.25615 6.47616 5.42545 6.40517 5.60243 6.40363C5.77941 6.40209 5.94992 6.47013 6.07723 6.59309L6.95 7.46586L9.17278 5.24309C9.30008 5.12013 9.47059 5.05209 9.64757 5.05363C9.82456 5.05517 9.99385 5.12616 10.119 5.25131C10.2442 5.37646 10.3151 5.54576 10.3167 5.72274C10.3182 5.89972 10.2502 6.07023 10.1272 6.19754Z"
      fill="#8E4B10"
    />
  </svg>
</template>

<style scoped></style>
