const countries = [
  {
    id: 1,
    name: "Afghanistan",
    code: "+93",
    flag: "https://flagsapi.com/AF/flat/64.png",
    callingCode: "AF",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 2,
    name: "Albania",
    code: "+355",
    flag: "https://flagsapi.com/AL/flat/64.png",
    callingCode: "AB",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 3,
    name: "Algeria",
    code: "+213",
    flag: "https://flagsapi.com/DZ/flat/64.png",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 4,
    name: "Andorra",
    code: "+376",
    flag: "https://flagsapi.com/AD/flat/64.png",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 5,
    name: "Angola",
    code: "+244",
    flag: "https://flagsapi.com/AO/flat/64.png",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 6,
    name: "Antigua and Barbuda",
    code: "+000",
    flag: "https://flagsapi.com/AG/flat/64.png",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 7,
    name: "Argentina",
    code: "+54",
    flag: "https://flagsapi.com/AR/flat/64.png",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 8,
    name: "Armenia",
    code: "+374",
    flag: "https://flagsapi.com/AM/flat/64.png",
    callingCode: null,
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 9,
    name: "Australia",
    code: "+61",
    callingCode: null,
    flag: "https://flagsapi.com/AU/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 10,
    name: "Austria",
    code: "+43",
    callingCode: null,
    flag: "https://flagsapi.com/AT/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 11,
    name: "Azerbaijan",
    code: "+994",
    callingCode: null,
    flag: "https://flagsapi.com/AZ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 12,
    name: "Bahamas",
    code: "+242",
    callingCode: null,
    flag: "https://flagsapi.com/BS/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 13,
    name: "Bahrain",
    code: "+973",
    callingCode: null,
    flag: "https://flagsapi.com/BH/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 14,
    name: "Bangladesh",
    code: "+880",
    callingCode: null,
    flag: "https://flagsapi.com/BD/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 15,
    name: "Barbados",
    code: "+246",
    callingCode: null,
    flag: "https://flagsapi.com/BB/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 16,
    name: "Belarus",
    code: "+375",
    callingCode: null,
    flag: "https://flagsapi.com/BY/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 17,
    name: "Belgium",
    code: "+32",
    callingCode: null,
    flag: "https://flagsapi.com/BE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 18,
    name: "Belize",
    code: "+501",
    callingCode: null,
    flag: "https://flagsapi.com/BZ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 19,
    name: "Benin",
    code: "+229",
    callingCode: null,
    flag: "https://flagsapi.com/BJ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 20,
    name: "Bhutan",
    code: "+975",
    callingCode: null,
    flag: "https://flagsapi.com/BT/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 21,
    name: "Bolivia",
    code: "+591",
    callingCode: null,
    flag: "https://flagsapi.com/BO/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 22,
    name: "Bosnia and Herzegovina",
    code: "+387",
    callingCode: null,
    flag: "https://flagsapi.com/BA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 23,
    name: "Botswana",
    code: "+267",
    callingCode: null,
    flag: "https://flagsapi.com/BW/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 24,
    name: "Brazil",
    code: "+55",
    callingCode: null,
    flag: "https://flagsapi.com/BR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 25,
    name: "Brunei",
    code: "+673",
    callingCode: null,
    flag: "https://flagsapi.com/BN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 26,
    name: "Bulgaria",
    code: "+395",
    callingCode: null,
    flag: "https://flagsapi.com/BG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 27,
    name: "Burkina Faso",
    code: "+226",
    callingCode: null,
    flag: "https://flagsapi.com/BF/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 28,
    name: "Burundi",
    code: "+257",
    callingCode: null,
    flag: "https://flagsapi.com/BI/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 31,
    name: "Cambodia",
    code: "+855",
    callingCode: null,
    flag: "https://flagsapi.com/KH/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 32,
    name: "Cameroon",
    code: "+237",
    callingCode: null,
    flag: "https://flagsapi.com/CM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 33,
    name: "Canada",
    code: "+1",
    callingCode: null,
    flag: "https://flagsapi.com/CA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 30,
    name: "Cape Verde",
    code: "+238",
    callingCode: null,
    flag: "https://flagsapi.com/CV/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 34,
    name: "Central African Republic",
    code: "+236",
    callingCode: null,
    flag: "https://flagsapi.com/CF/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 35,
    name: "Chad",
    code: "+235",
    callingCode: null,
    flag: "https://flagsapi.com/TD/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 36,
    name: "Chile",
    code: "+56",
    callingCode: null,
    flag: "https://flagsapi.com/CL/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 37,
    name: "China",
    code: "+86",
    callingCode: null,
    flag: "https://flagsapi.com/CN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 38,
    name: "Colombia",
    code: "+57",
    callingCode: null,
    flag: "https://flagsapi.com/CO/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 39,
    name: "Comoros",
    code: "+269",
    callingCode: null,
    flag: "https://flagsapi.com/KM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 40,
    name: "Congo",
    code: "+242",
    callingCode: null,
    flag: "https://flagsapi.com/CG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 41,
    name: "Costa Rica",
    code: "+506",
    callingCode: null,
    flag: "https://flagsapi.com/CR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 29,
    name: "Côte d'Ivoire",
    code: "+225",
    callingCode: null,
    flag: "https://flagsapi.com/CI/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 42,
    name: "Croatia",
    code: "+385",
    callingCode: null,
    flag: "https://flagsapi.com/HR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 43,
    name: "Cuba",
    code: "+53",
    callingCode: null,
    flag: "https://flagsapi.com/CU/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 44,
    name: "Cyprus",
    code: "+357",
    callingCode: null,
    flag: "https://flagsapi.com/CY/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 45,
    name: "Czech",
    code: "+420",
    callingCode: null,
    flag: "https://flagsapi.com/CZ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 46,
    name: "Democratic Republic of the Congo",
    code: "+243",
    callingCode: null,
    flag: "https://flagsapi.com/CD/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 47,
    name: "Denmark",
    code: "+45",
    callingCode: null,
    flag: "https://flagsapi.com/DK/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 48,
    name: "Djibouti",
    code: "+253",
    callingCode: null,
    flag: "https://flagsapi.com/DJ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 49,
    name: "Dominica",
    code: "+767",
    callingCode: null,
    flag: "https://flagsapi.com/DM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 50,
    name: "Dominican Republic",
    code: "+809",
    callingCode: null,
    flag: "https://flagsapi.com/DO/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 51,
    name: "Ecuador",
    code: "+593",
    callingCode: null,
    flag: "https://flagsapi.com/EC/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 52,
    name: "Egypt",
    code: "+20",
    callingCode: null,
    flag: "https://flagsapi.com/EG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 53,
    name: "El Salvador",
    code: "+240",
    callingCode: null,
    flag: "https://flagsapi.com/SV/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 54,
    name: "Equatorial Guinea",
    code: "+263",
    callingCode: null,
    flag: "https://flagsapi.com/GQ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 55,
    name: "Eritrea",
    code: "+291",
    callingCode: null,
    flag: "https://flagsapi.com/ER/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 56,
    name: "Estonia",
    code: "+372",
    callingCode: null,
    flag: "https://flagsapi.com/EE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 57,
    name: "Eswatini",
    code: "+268",
    callingCode: null,
    flag: "https://flagsapi.com/SZ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 58,
    name: "Ethiopia",
    code: "+251",
    callingCode: null,
    flag: "https://flagsapi.com/ET/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 59,
    name: "Fiji",
    code: "+679",
    callingCode: null,
    flag: "https://flagsapi.com/FJ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 60,
    name: "Finland",
    code: "+358",
    callingCode: null,
    flag: "https://flagsapi.com/FI/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 61,
    name: "France",
    code: "+33",
    callingCode: null,
    flag: "https://flagsapi.com/FR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 62,
    name: "Gabon",
    code: "+241",
    callingCode: null,
    flag: "https://flagsapi.com/GA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 63,
    name: "Gambia",
    code: "+220",
    callingCode: null,
    flag: "https://flagsapi.com/GM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 64,
    name: "Georgia",
    code: "+995",
    callingCode: null,
    flag: "https://flagsapi.com/GE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 65,
    name: "Germany",
    code: "+49",
    callingCode: null,
    flag: "https://flagsapi.com/DE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 66,
    name: "Ghana",
    code: "+233",
    callingCode: null,
    flag: "https://flagsapi.com/GH/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 67,
    name: "Greece",
    code: "+30",
    callingCode: null,
    flag: "https://flagsapi.com/GR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 68,
    name: "Grenada",
    code: "+473",
    callingCode: null,
    flag: "https://flagsapi.com/GD/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 69,
    name: "Guatemala",
    code: "+502",
    callingCode: null,
    flag: "https://flagsapi.com/GT/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 70,
    name: "Guinea",
    code: "+224",
    callingCode: null,
    flag: "https://flagsapi.com/GN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 71,
    name: "Guinea-Bissau",
    code: "+245",
    callingCode: null,
    flag: "https://flagsapi.com/GW/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 72,
    name: "Guyana",
    code: "+592",
    callingCode: null,
    flag: "https://flagsapi.com/GY/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 73,
    name: "Haiti",
    code: "+509",
    callingCode: null,
    flag: "https://flagsapi.com/HT/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 74,
    name: "Holy See",
    code: "+379",
    callingCode: null,
    flag: "https://flagsapi.com/VA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 75,
    name: "Honduras",
    code: "+504",
    callingCode: null,
    flag: "https://flagsapi.com/HN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 76,
    name: "Hungary",
    code: "+36",
    callingCode: null,
    flag: "https://flagsapi.com/HU/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 77,
    name: "Iceland",
    code: "+354",
    callingCode: null,
    flag: "https://flagsapi.com/IS/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 78,
    name: "India",
    code: "+91",
    callingCode: null,
    flag: "https://flagsapi.com/IN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 79,
    name: "Indonesia",
    code: "+62",
    callingCode: null,
    flag: "https://flagsapi.com/ID/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 80,
    name: "Iran",
    code: "+98",
    callingCode: null,
    flag: "https://flagsapi.com/IR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 81,
    name: "Iraq",
    code: "+964",
    callingCode: null,
    flag: "https://flagsapi.com/IQ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 82,
    name: "Ireland",
    code: "+353",
    callingCode: null,
    flag: "https://flagsapi.com/IE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 83,
    name: "Israel",
    code: "+972",
    callingCode: null,
    flag: "https://flagsapi.com/IL/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 84,
    name: "Italy",
    code: "+39",
    callingCode: null,
    flag: "https://flagsapi.com/IT/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 85,
    name: "Jamaica",
    code: "+876",
    callingCode: null,
    flag: "https://flagsapi.com/JM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 86,
    name: "Japan",
    code: "+81",
    callingCode: null,
    flag: "https://flagsapi.com/JP/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 87,
    name: "Jordan",
    code: "+962",
    callingCode: null,
    flag: "https://flagsapi.com/JO/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 88,
    name: "Kazakhstan",
    code: "+7",
    callingCode: null,
    flag: "https://flagsapi.com/KZ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 89,
    name: "Kenya",
    code: "+254",
    callingCode: null,
    flag: "https://flagsapi.com/KE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 90,
    name: "Kiribati",
    code: "+686",
    callingCode: null,
    flag: "https://flagsapi.com/KI/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 91,
    name: "Kuwait",
    code: "+965",
    callingCode: null,
    flag: "https://flagsapi.com/KW/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 92,
    name: "Kyrgyzstan",
    code: "+996",
    callingCode: null,
    flag: "https://flagsapi.com/KG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 93,
    name: "Laos",
    code: "+856",
    callingCode: null,
    flag: "https://flagsapi.com/LA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 94,
    name: "Latvia",
    code: "+371",
    callingCode: null,
    flag: "https://flagsapi.com/LV/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 95,
    name: "Lebanon",
    code: "+961",
    callingCode: null,
    flag: "https://flagsapi.com/LB/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 96,
    name: "Lesotho",
    code: "+266",
    callingCode: null,
    flag: "https://flagsapi.com/LS/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 97,
    name: "Liberia",
    code: "+231",
    callingCode: null,
    flag: "https://flagsapi.com/LR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 98,
    name: "Libya",
    code: "+218",
    callingCode: null,
    flag: "https://flagsapi.com/LY/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 99,
    name: "Liechtenstein",
    code: "+423",
    callingCode: null,
    flag: "https://flagsapi.com/LI/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 100,
    name: "Lithuania",
    code: "+370",
    callingCode: null,
    flag: "https://flagsapi.com/LT/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 101,
    name: "Luxembourg",
    code: "+352",
    callingCode: null,
    flag: "https://flagsapi.com/LU/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 102,
    name: "Madagascar",
    code: "+261",
    callingCode: null,
    flag: "https://flagsapi.com/MG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 103,
    name: "Malawi",
    code: "+265",
    callingCode: null,
    flag: "https://flagsapi.com/MW/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 104,
    name: "Malaysia",
    code: "+60",
    callingCode: null,
    flag: "https://flagsapi.com/MY/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 105,
    name: "Maldives",
    code: "+960",
    callingCode: null,
    flag: "https://flagsapi.com/MV/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 106,
    name: "Mali",
    code: "+223",
    callingCode: null,
    flag: "https://flagsapi.com/ML/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 107,
    name: "Malta",
    code: "+356",
    callingCode: null,
    flag: "https://flagsapi.com/MT/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 108,
    name: "Marshall Islands",
    code: "+692",
    callingCode: null,
    flag: "https://flagsapi.com/MH/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 109,
    name: "Mauritania",
    code: "+222",
    callingCode: null,
    flag: "https://flagsapi.com/MR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 110,
    name: "Mauritius",
    code: "+230",
    callingCode: null,
    flag: "https://flagsapi.com/MU/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 111,
    name: "Mexico",
    code: "+52",
    callingCode: null,
    flag: "https://flagsapi.com/MX/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 112,
    name: "Micronesia",
    code: "+691",
    callingCode: null,
    flag: "https://flagsapi.com/FM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 113,
    name: "Moldova",
    code: "+373",
    callingCode: null,
    flag: "https://flagsapi.com/MD/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 114,
    name: "Monaco",
    code: "+377",
    callingCode: null,
    flag: "https://flagsapi.com/MC/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 115,
    name: "Mongolia",
    code: "+976",
    callingCode: null,
    flag: "https://flagsapi.com/MN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 116,
    name: "Montenegro",
    code: "+382",
    callingCode: null,
    flag: "https://flagsapi.com/ME/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 117,
    name: "Morocco",
    code: "+212",
    callingCode: null,
    flag: "https://flagsapi.com/MA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 118,
    name: "Mozambique",
    code: "+258",
    callingCode: null,
    flag: "https://flagsapi.com/MZ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 119,
    name: "Myanmar",
    code: "+95",
    callingCode: null,
    flag: "https://flagsapi.com/MM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 120,
    name: "Namibia",
    code: "+264",
    callingCode: null,
    flag: "https://flagsapi.com/NA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 121,
    name: "Nauru",
    code: "+674",
    callingCode: null,
    flag: "https://flagsapi.com/NR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 122,
    name: "Nepal",
    code: "+977",
    callingCode: null,
    flag: "https://flagsapi.com/NP/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 123,
    name: "Netherlands",
    code: "+31",
    callingCode: null,
    flag: "https://flagsapi.com/NL/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 124,
    name: "New Zealand",
    code: "+64",
    callingCode: null,
    flag: "https://flagsapi.com/NZ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 125,
    name: "Nicaragua",
    code: "+505",
    callingCode: null,
    flag: "https://flagsapi.com/NI/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 126,
    name: "Niger",
    code: "+227",
    callingCode: null,
    flag: "https://flagsapi.com/NE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 127,
    name: "Nigeria",
    code: "+234",
    callingCode: null,
    flag: "https://flagsapi.com/NG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 128,
    name: "North Korea",
    code: "+850",
    callingCode: null,
    flag: "https://flagsapi.com/KP/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 129,
    name: "North Macedonia",
    code: "+389",
    callingCode: null,
    flag: "https://flagsapi.com/MK/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 130,
    name: "Norway",
    code: "+47",
    callingCode: null,
    flag: "https://flagsapi.com/NO/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 131,
    name: "Oman",
    code: "+968",
    callingCode: null,
    flag: "https://flagsapi.com/OM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.231Z",
    updatedAt: "2021-03-15T10:57:04.231Z",
    deletedAt: null,
  },
  {
    id: 132,
    name: "Pakistan",
    code: "+92",
    callingCode: null,
    flag: "https://flagsapi.com/PK/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 133,
    name: "Palau",
    code: "+680",
    callingCode: null,
    flag: "https://flagsapi.com/PW/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 134,
    name: "Palestine",
    code: "+970",
    callingCode: null,
    flag: "https://flagsapi.com/PS/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 135,
    name: "Panama",
    code: "+507",
    callingCode: null,
    flag: "https://flagsapi.com/PA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 136,
    name: "Papua New Guinea",
    code: "+675",
    callingCode: null,
    flag: "https://flagsapi.com/PG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 137,
    name: "Paraguay",
    code: "+595",
    callingCode: null,
    flag: "https://flagsapi.com/PY/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 138,
    name: "Peru",
    code: "+51",
    callingCode: null,
    flag: "https://flagsapi.com/PE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 139,
    name: "Philippines",
    code: "+63",
    callingCode: null,
    flag: "https://flagsapi.com/PH/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 140,
    name: "Poland",
    code: "+48",
    callingCode: null,
    flag: "https://flagsapi.com/PL/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 141,
    name: "Portugal",
    code: "+351",
    callingCode: null,
    flag: "https://flagsapi.com/PT/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 142,
    name: "Qatar",
    code: "+974",
    callingCode: null,
    flag: "https://flagsapi.com/QA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 143,
    name: "Romania",
    code: "+40",
    callingCode: null,
    flag: "https://flagsapi.com/RO/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 144,
    name: "Russia",
    code: "+7",
    callingCode: null,
    flag: "https://flagsapi.com/RU/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 145,
    name: "Rwanda",
    code: "+250",
    callingCode: null,
    flag: "https://flagsapi.com/RW/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 146,
    name: "Saint Kitts and Nevis",
    code: "+869",
    callingCode: null,
    flag: "https://flagsapi.com/KN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 147,
    name: "Saint Lucia",
    code: "+758",
    callingCode: null,
    flag: "https://flagsapi.com/LC/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 148,
    name: "Saint Vincent and the Grenadines",
    code: "+784",
    callingCode: null,
    flag: "https://flagsapi.com/VC/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 149,
    name: "Samoa",
    code: "+685",
    callingCode: null,
    flag: "https://flagsapi.com/WS/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 150,
    name: "San Marino",
    code: "+378",
    callingCode: null,
    flag: "https://flagsapi.com/SM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 151,
    name: "Sao Tome and Principe",
    code: "+239",
    callingCode: null,
    flag: "https://flagsapi.com/ST/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 152,
    name: "Saudi Arabia",
    code: "+996",
    callingCode: null,
    flag: "https://flagsapi.com/SA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 153,
    name: "Senegal",
    code: "+221",
    callingCode: null,
    flag: "https://flagsapi.com/SN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 154,
    name: "Serbia",
    code: "+381",
    callingCode: null,
    flag: "https://flagsapi.com/RS/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 155,
    name: "Seychelles",
    code: "+248",
    callingCode: null,
    flag: "https://flagsapi.com/SC/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 156,
    name: "Sierra Leone",
    code: "+232",
    callingCode: null,
    flag: "https://flagsapi.com/SL/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 157,
    name: "Singapore",
    code: "+65",
    callingCode: null,
    flag: "https://flagsapi.com/SG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 158,
    name: "Slovakia",
    code: "+421",
    callingCode: null,
    flag: "https://flagsapi.com/SK/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 159,
    name: "Slovenia",
    code: "+386",
    callingCode: null,
    flag: "https://flagsapi.com/SI/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 160,
    name: "Solomon Islands",
    code: "+677",
    callingCode: null,
    flag: "https://flagsapi.com/SB/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 161,
    name: "Somalia",
    code: "+252",
    callingCode: null,
    flag: "https://flagsapi.com/SO/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 162,
    name: "South Africa",
    code: "+27",
    callingCode: null,
    flag: "https://flagsapi.com/ZA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 163,
    name: "South Korea",
    code: "+82",
    callingCode: null,
    flag: "https://flagsapi.com/KR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 164,
    name: "South Sudan",
    code: "+211",
    callingCode: null,
    flag: "https://flagsapi.com/SS/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 165,
    name: "Spain",
    code: "+34",
    callingCode: null,
    flag: "https://flagsapi.com/ES/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 166,
    name: "Sri Lanka",
    code: "+94",
    callingCode: null,
    flag: "https://flagsapi.com/LK/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 167,
    name: "Sudan",
    code: "+249",
    callingCode: null,
    flag: "https://flagsapi.com/SD/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 168,
    name: "Suriname",
    code: "+597",
    callingCode: null,
    flag: "https://flagsapi.com/SR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 169,
    name: "Sweden",
    code: "+46",
    callingCode: null,
    flag: "https://flagsapi.com/SE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 170,
    name: "Switzerland",
    code: "+41",
    callingCode: null,
    flag: "https://flagsapi.com/CH/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 171,
    name: "Syria",
    code: "+963",
    callingCode: null,
    flag: "https://flagsapi.com/SY/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 172,
    name: "Tajikistan",
    code: "+7",
    callingCode: null,
    flag: "https://flagsapi.com/TJ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 173,
    name: "Tanzania",
    code: "+255",
    callingCode: null,
    flag: "https://flagsapi.com/TZ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 174,
    name: "Thailand",
    code: "+66",
    callingCode: null,
    flag: "https://flagsapi.com/TH/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 175,
    name: "Timor-Leste",
    code: "+670",
    callingCode: null,
    flag: "https://flagsapi.com/TL/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 176,
    name: "Togo",
    code: "+228",
    callingCode: null,
    flag: "https://flagsapi.com/TG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 177,
    name: "Tonga",
    code: "+676",
    callingCode: null,
    flag: "https://flagsapi.com/TO/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 178,
    name: "Trinidad and Tobago",
    code: "+868",
    callingCode: null,
    flag: "https://flagsapi.com/TT/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 179,
    name: "Tunisia",
    code: "+216",
    callingCode: null,
    flag: "https://flagsapi.com/TN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 180,
    name: "Turkey",
    code: "+90",
    callingCode: null,
    flag: "https://flagsapi.com/TR/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 181,
    name: "Turkmenistan",
    code: "+993",
    callingCode: null,
    flag: "https://flagsapi.com/TM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 182,
    name: "Tuvalu",
    code: "+688",
    callingCode: null,
    flag: "https://flagsapi.com/TV/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 183,
    name: "Uganda",
    code: "+256",
    callingCode: null,
    flag: "https://flagsapi.com/UG/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 184,
    name: "Ukraine",
    code: "+380",
    callingCode: null,
    flag: "https://flagsapi.com/UA/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 185,
    name: "United Arab Emirates",
    code: "+971",
    callingCode: null,
    flag: "https://flagsapi.com/AE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 186,
    name: "United Kingdom",
    code: "+44",
    callingCode: null,
    flag: "https://flagsapi.com/GB/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 187,
    name: "United States of America",
    code: "+1",
    callingCode: null,
    flag: "https://flagsapi.com/US/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 188,
    name: "Uruguay",
    code: "+598",
    callingCode: null,
    flag: "https://flagsapi.com/UY/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 189,
    name: "Uzbekistan",
    code: "+7",
    callingCode: null,
    flag: "https://flagsapi.com/UZ/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 190,
    name: "Vanuatu",
    code: "+678",
    callingCode: null,
    flag: "https://flagsapi.com/VU/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 196,
    name: "Various Countries",
    code: "999",
    flag: "https://flagsapi.com/VC/flat/64.png",
    callingCode: "AF",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.230Z",
    updatedAt: "2021-03-15T10:57:04.230Z",
    deletedAt: null,
  },
  {
    id: 191,
    name: "Venezuela",
    code: "+58",
    callingCode: null,
    flag: "https://flagsapi.com/VE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 192,
    name: "Vietnam",
    code: "+84",
    callingCode: null,
    flag: "https://flagsapi.com/VN/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 193,
    name: "Yemen",
    code: "+381",
    callingCode: null,
    flag: "https://flagsapi.com/YE/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 194,
    name: "Zambia",
    code: "+260",
    callingCode: null,
    flag: "https://flagsapi.com/ZM/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
  {
    id: 195,
    name: "Zimbabwe",
    code: "+263",
    callingCode: null,
    flag: "https://flagsapi.com/ZW/flat/64.png",
    currency: null,
    createdBy: null,
    updatedBy: null,
    deletedBy: null,
    createdAt: "2021-03-15T10:57:04.232Z",
    updatedAt: "2021-03-15T10:57:04.232Z",
    deletedAt: null,
  },
];
export default countries;
