import Api from "@/services/api";
import {
  getMissionsPaginated,
  searchMissionsEndpoint,
} from "@/services/endpoints";
import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    current_page: 1,
    response: [],
    error: null,
    total_data: null,
    total_pages: 1,
    app_loading: false,
    order_id: null,
    headers: [
      {
        sectionName: "Basic Information",
        sectionFields: [
          { key: "contract_type", label: "Contract" },
          { key: "expert", label: "Expert" },
          {
            key: "is_with_travel",
            label: "Mission with travel?",
            type: "boolean",
          },
          { key: "countries", label: "Country(ies)", type: "list" },
          { key: "mission_start_date", label: "Start Date", type: "date" },
          { key: "mission_end_date", label: "End Date", type: "date" },
        ],
      },
      {
        sectionName: "Work Information",
        sectionFields: [
          { key: "number_of_working_days", label: "Number of Working Days" },
          { key: "remaining_working_days", label: "Remaining Working Days" },
          { key: "travel_days", label: "Number of Days Abroad" },
          { key: "remaining_travel_days", label: "Remaining Travel Days" },
          {
            key: "number_of_home_based_days",
            label: "Number of Home-based Days",
          },
          {
            key: "remaining_home_based_days",
            label: "Remaining Home-based Days",
          },
          { key: "report", label: "Deliverable Reports", type: "list" },
        ],
      },
      {
        sectionName: "Allowances",
        sectionFields: [
          {
            key: "number_of_daily_food_allowance",
            label: "Food Allowance (daily)",
          },
          {
            key: "number_of_8_24_food_allowance",
            label: "Food Allowance (8-24)",
          },
          {
            key: "number_of_night_food_allowance",
            label: "Food Allowance (night)",
          },
          {
            key: "number_of_overnight_allowance",
            label: "Overnight Allowance",
          },
          { key: "number_of_hotel_cost", label: "Hotel Cost" },
          {
            key: "number_of_transportation_cost_per_day",
            label: "Transportation",
          },
        ],
      },
      {
        sectionName: "Other Costs",
        sectionFields: [
          {
            key: "is_other_costs",
            label: "Are there other costs?",
            type: "boolean",
          },
          { key: "other_costs_amount", label: "Cost Items" },
          {
            key: "amount_other_up_to_max_eur",
            label: "Amount",
            type: "currency",
          },
          { key: "transport_cost_amount_type", label: "Amount Type" },
          {
            key: "is_ticket_provided",
            label: "Ticket Provided For?",
            type: "boolean",
          },
          { key: "ticket_amount", label: "Ticket Amount", type: "currency" },
          { key: "cost_position", label: "Cost Position" },
        ],
      },
      {
        sectionName: "Additional Information",
        sectionFields: [
          {
            key: "is_transport_via_project_office",
            label: "Transport via project office?",
            type: "boolean",
          },
          { key: "is_icon_visa", label: "Visa on ICON", type: "boolean" },

          { key: "mission_task_description", label: "Task Details" },
        ],
      },
    ],
  },

  mutations: {
    SET_RESPONSE_TO_STATE(state: any, payload: any) {
      state.response = payload;
    },
    SET_ERROR_TO_STATE(state: any, payload: any) {
      state.error = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.current_page = payload;
    },
    SET_TOTAL_DATA_TO_STATE(state: any, payload: any) {
      state.total_data = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.total_pages = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.app_loading = payload;
    },
    SET_ORDER_ID_TO_STATE(state: any, payload: any) {
      state.order_id = payload;
    },
  },
  getters: {
    getAllOrders: (state: any) =>
      state.response.map((order: any) => {
        return {
          ...order,
          order_id: order.id,
          order_period: `${order.mission_start_date?.formattedDate()} - ${order.mission_end_date?.formattedDate()}`,
        };
      }),
    getError: (state: any) => state.error,
    getCurrentPage: (state: any) => state.current_page,
    getTotalData: (state: any) => state.total_data,
    getTotalPages: (state: any) => state.total_pages,
    getAppLoading: (state: any) => state.app_loading,
    getOrderId: (state: any) => state.order_id,
    getHeaders: (state: any) => state.headers,
  },

  actions: {
    async fetchAllProjectOrders(
      { commit }: ActionContext<any, any>,
      payload: any
    ) {
      try {
        if (payload.load_page) {
          commit("SET_APP_LOADING_TO_STATE", true);
        }
        const response = await Api().get(
          `${getMissionsPaginated}${payload.project_id}/${payload.page_number}/`
        );
        if (response.data.status === "success") {
          commit("SET_APP_LOADING_TO_STATE", false);
          commit("SET_RESPONSE_TO_STATE", response.data.data);
          commit("SET_CURRENT_PAGE_TO_STATE", response.data.current_page);
          commit("SET_TOTAL_DATA_TO_STATE", response.data.total_data);
          commit("SET_TOTAL_PAGES_TO_STATE", response.data.total_pages);
        }
      } catch (error) {
        commit("SET_ERROR_TO_STATE", error);
      }
    },
    async searchProjectOrders(
      { commit }: ActionContext<any, any>,
      payload: any
    ) {
      try {
        const response = await Api().post(
          `${searchMissionsEndpoint}${payload.page_number}/`,
          { search_term: payload.query }
        );
        commit("SET_RESPONSE_TO_STATE", response.data.data);
        commit("SET_CURRENT_PAGE_TO_STATE", response.data.current_page);
        commit("SET_TOTAL_DATA_TO_STATE", response.data.total_data);
        commit("SET_TOTAL_PAGES_TO_STATE", response.data.total_pages);
      } catch (error) {
        commit("SET_ERROR_TO_STATE", error);
      }
    },
  },
};
