import Api from "@/services/api";
import {
  getManualInvoiceSigningDetails,
  getSanctionedExpenseSigningDetails,
  getSettlementForSanctionedExpense,
  getSettlementForManualInvoice,
  getSingleSanctionedExpense,
  getAllCostCenters as getAllCostCentersEndpoint,
} from "@/services/endpoints";
import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    appLoading: false,
    sanctionedExpenses: [],
    addSanctionedExpenseTableHeaders: [],
    allExpensesTableHeaders: [
      {
        key: "title",
        label: "Title",
      },
      {
        key: "budget_item_id",
        label: "Link to Budget",
      },
      {
        key: "number_of_units",
        label: "No. of Units",
      },
      {
        key: "amount_per_unit",
        label: "Amt per Unit",
        type: "digit",
      },
      {
        key: "total_amount",
        label: "Total",
        type: "currency",
      },
    ],
    newExpenseTableHeaders: [
      {
        key: "title",
        label: "Title",
      },
      {
        key: "budget_id",
        label: "Link to Budget",
      },
      {
        key: "number_of_units",
        label: "No. of Units",
      },
      {
        key: "requested",
        label: "Requested",
      },
    ],
    pastExpensesTableHeaders: [
      {
        key: "title",
        label: "Title",
      },
      {
        key: "invoice_number",
        label: "Invoice Number",
      },
      {
        key: "requested",
        label: "Requested",
      },
      {
        key: "approved",
        label: "Approved",
      },
    ],
    editExpenseSelectFields: [
      {
        key: "invoice_id",
        label: "Invoice Number",
        placeholder: "Select invoice number",
      },
      {
        key: "approved_amount",
        label: "Approved Amount",
        placeholder: "Enter approved amount",
      },
      {
        key: "booking_date",
        label: "Booking Date",
        placeholder: "",
      },
      {
        key: "payment_date",
        label: "Payment Date",
        placeholder: "",
      },
      // {
      //   key: "status",
      //   label: "Status",
      //   placeholder: "Select action",
      // },
    ],
    allExpensesData: [],
    editSanctionedExpenseId: null,
    settlementDetailsForSanctionedExpense: [],
    sanctionedExpenseSigningDetails: {},
    costCenters: [],
    fromHome: false,
    isEditMode: false,
    tabsInEditMode: [],
    currentSingleExpense: {},
  },
  getters: {
    getSanctionedExpenses: (state: any) => state.sanctionedExpenses,
    getAddSanctionedExpenseTableHeaders: (state: any) =>
      state.addSanctionedExpenseTableHeaders,
    getAllExpensesTableHeaders: (state: any) => state.allExpensesTableHeaders,
    getNewExpenseTableHeaders: (state: any) => state.newExpenseTableHeaders,
    getPastExpensesTableHeaders: (state: any) => state.pastExpensesTableHeaders,
    getEditExpenseSelectFields: (state: any) => state.editExpenseSelectFields,
    getAllExpensesData: (state: any) => state.allExpensesData,
    getEditSanctionedExpenseId: (state: any) => state.editSanctionedExpenseId,
    getSettlementDetailsForSanctionedExpense: (state: any) =>
      state.settlementDetailsForSanctionedExpense,
    getSanctionedExpenseSigningDetails: (state: any) =>
      state.sanctionedExpenseSigningDetails,
    getCostCenters: (state: any) => state.costCenters,
    hasCostCenters: (state: any) => state.costCenters.length > 0,
    isFromHome: (state: any) => state.fromHome,
    canPreviewSigningForm: (
      state: any,
      getters: any,
      rootState: any,
      rootGetters: any
    ) => {
      return (
        rootGetters["auth/canView"].some(
          (module: any) =>
            module.module_name === "Sub-contract Settlement" &&
            module.module_parent_name === "Sub-contract"
        ) ||
        rootGetters["auth/canEdit"].some(
          (module: any) =>
            module.module_name === "Sub-contract Settlement" &&
            module.module_parent_name === "Sub-contract"
        )
      );
    },
    getIsEditMode: (state: any) => state.isEditMode,
    getTabsInEditMode: (state: any) => state.tabsInEditMode,
    getCurrentSingleExpense: (state: any) => state.currentSingleExpense,
    getAppLoadingState: (state: any) => state.appLoading,
    // isReadOnly: (state: any, rootGetters: any) => {
    //   return state.settlementDetailsForSanctionedExpense.sanctioned_expense?.status === "paid" || rootGetters["auth/isCeo"];
    // }
  },
  mutations: {
    setApploadingState(state: any, payload: any) {
      state.appLoading = payload;
    },
    setSanctionedExpenses(state: any, payload: any) {
      state.sanctionedExpenses = payload;
    },
    setAllExpensesData(state: any, payload: any) {
      state.allExpensesData = payload;
    },
    addRowToAllExpensesData(state: any, payload: any) {
      state.allExpensesData.unshift(payload);
    },
    updateAllExpensesData(state: any, payload: any) {
      // const {}
      state.allExpensesData[payload.index] = payload;
    },
    deleteRowFromAllExpensesData(state: any, payload: any) {
      state.allExpensesData.splice(payload, 1);
    },
    setEditMode(state: any, payload: boolean) {
      state.isEditMode = payload;
    },
    setEditSanctionedExpenseId(state: any, payload: any) {
      state.editSanctionedExpenseId = payload;
    },
    setSettlementDetailsForSanctionedExpense(state: any, payload: any) {
      state.settlementDetailsForSanctionedExpense = payload;
    },
    setSanctionedExpenseSigningDetails(state: any, payload: any) {
      state.sanctionedExpenseSigningDetails = payload;
    },
    setCostCenters(state: any, payload: any) {
      state.costCenters = payload;
    },
    setFromHome(state: any, payload: any) {
      state.fromHome = payload;
    },
    addTabToEditMode(state: any, tabName: string) {
      state.tabsInEditMode.push(tabName);
    },
    removeTabFromEditMode(state: any, index: number) {
      state.tabsInEditMode.splice(index, 1);
    },
    setCurrentSingleExpense(state: any, payload: any) {
      state.currentSingleExpense = payload;
    },
  },
  actions: {
    getSanctionedExpensesSettlementData(
      context: ActionContext<any, any>,
      id: any
    ) {
      Api()
        .get(
          `${getSettlementForSanctionedExpense}${context.getters.getEditSanctionedExpenseId}/`
        )
        .then((res: any) => {
          context.commit(
            "setSettlementDetailsForSanctionedExpense",
            res.data?.data
          );
        })
        .catch(() => {
          // console.log(err);
        });
    },
    getAllCostCenters(context: ActionContext<any, any>, page: any) {
      Api()
        .get(getAllCostCentersEndpoint)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("setCostCenters", response.data?.data);
          }
        })
        .catch(() => {
          // console.log(error)
        });
    },
    getSigningDetails(context: ActionContext<any, any>, payload: any) {
      // const id = context.getters.getEditSanctionedExpenseId;
      Api()
        .get(`${getSanctionedExpenseSigningDetails}${payload.id}/`)
        .then((res) => {
          context.commit("setSanctionedExpenseSigningDetails", res.data.data);
        })
        .catch(() => {
          // console.log(err);
        });
    },
    getManualInvoiceSigningDetails(
      context: ActionContext<any, any>,
      payload: any
    ) {
      Api()
        .get(`${getManualInvoiceSigningDetails}${payload.id}`)
        .then((res) => {
          context.commit("setSanctionedExpenseSigningDetails", res.data.data);
        })
        .catch(() => {
          // console.log(err)
        });
    },
    getSingleSanctionedExpense(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("setApploadingState", true);
      }

      Api()
        .get(
          `${getSingleSanctionedExpense}${context.getters.getEditSanctionedExpenseId}/`
        )
        .then((res) => {
          context.commit("setApploadingState", false);
          context.commit("setCurrentSingleExpense", res.data?.data);
        })
        .catch(() => {
          // console.log(err);
        });
    },
  },
};
