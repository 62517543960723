<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="-0.0078125" width="28" height="28" rx="14" fill="#F2F4F7" />
    <g clip-path="url(#clip0_30336_149869)">
      <path
        d="M8 9.99479H9.33333M9.33333 9.99479H20M9.33333 9.99479V19.3281C9.33333 19.6817 9.47381 20.0209 9.72386 20.2709C9.97391 20.521 10.313 20.6615 10.6667 20.6615H17.3333C17.687 20.6615 18.0261 20.521 18.2761 20.2709C18.5262 20.0209 18.6667 19.6817 18.6667 19.3281V9.99479H9.33333ZM11.3333 9.99479V8.66146C11.3333 8.30784 11.4738 7.9687 11.7239 7.71865C11.9739 7.4686 12.313 7.32812 12.6667 7.32812H15.3333C15.687 7.32812 16.0261 7.4686 16.2761 7.71865C16.5262 7.9687 16.6667 8.30784 16.6667 8.66146V9.99479M12.6667 13.3281V17.3281M15.3333 13.3281V17.3281"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_30336_149869">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(6 5.99219)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
