// import Api from "@/services/api"
import Api from "@/services/api";
import {
  getEmployeeAbsence,
  getMyAnnouncements,
  getAllForeignEmployees,
  getAllEmployeeAbsentByTeamLeader,
  getEmployeeContactInfo,
  teamAnnouncement,
  positionAnnouncement,
} from "@/services/endpoints";
import { ActionContext } from "vuex";

interface StateObject {
  [key: string]: any; // Define an index signature
}

export default {
  namespaced: true,
  state: {
    leaveId: null,
    status: "",
    requestedby: "",
    requestedbyHome: "",
    approved: "",
    month: "",
    yearHome: "",
    monthHome: "",
    year: "",
    home: false,
    leaveType: "",
    currentPage: 1,
    totalPages: null,
    absenceTotalPages: null,
    totalData: null,
    employees: [],
    qualifications: [],
    personalData: null,
    userKey: null,
    userInfo: null,
    tabId: 0,
    tableHeaders: null,
    personalInfo: "",
    contactInfo: "",
    employmentInfo: "",
    editEmployee: true,
    editButton: false,
    editUserKey: null,
    employeeName: "",
    jobHistories: [],
    groupedModules: [],
    filterBys: [],
    publicHolidays: [],
    personalLeaves: [],
    employeeLeaves: [],
    allLeaves: [],
    requestedLeaves: [],
    bankHeaders: ["Bank Name", "Country", "Bank Address"],
    controllers: [],
    allBankDetails: [],
    allAbsence: [],
    allEmployeesAbsent: [],
    employee_user_key: "",
    isEmployeesUpdate: {},
    allAnnouncements: [],
    userDocuments: [],
    teams: [],
    positions: [],
    projectMangers: [],
  },

  mutations: {
    SET_PROJECT_MANAGERS_TO_STATE(state: any, payload: any) {
      state.projectMangers = payload;
    },
    SET_EMPLOYEES_TO_STATE(state: any, payload: any) {
      state.employees = payload;
    },
    SET_EMPLOYEE_USER_KEY_FROM_HOME(state: any, payload: any) {
      state.employee_user_key = payload;
    },
    SET_EDIT_USER_KEY_TO_STATE(state: any, payload: any) {
      state.editUserKey = payload;
    },
    SET_JOB_HISTORIES_TO_STATE(state: any, payload: any) {
      state.jobHistories = payload;
    },
    SET_PERSONAL_LEAVE_TO_STATE(state: any, payload: any) {
      state.personalLeaves = payload;
    },
    SET_EMPLOYEE_LEAVES_TO_STATE(state: any, payload: any) {
      state.employeeLeaves = payload;
    },
    SET_ALL_LEAVES_TO_STATE(state: any, payload: any) {
      state.allLeaves = payload;
    },
    SET_REQUEST_LEAVES_TO_STATE(state: any, payload: any) {
      state.requestedLeaves = payload;
    },
    SET_APPROVED_TO_STATE(state: any, payload: any) {
      state.approved = payload;
    },
    SET_EMPLOYEE_NAME_STATE(state: any, payload: any) {
      state.employeeName = payload;
    },
    SET_TABLE_HEADERS_TO_STATE(state: any, payload: any) {
      state.tableHeaders = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_ABSENCE_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.absenceTotalPages = payload;
    },
    SET_CURRENT_TAB_TO_STATE(state: any, payload: any) {
      state.tabId = payload;
    },
    SET_PERSONAl_DATA_TO_STATE(state: any, payload: any) {
      state.personalData = payload;
    },
    SET_PERSONAl_INFO_TO_STATE(state: any, payload: any) {
      state.personalInfo = payload[0];
    },
    SET_EMPLOYEE_UPDATE(state: any, payload: any) {
      state.isEmployeesUpdate = { ...state.isEmployeesUpdate, ...payload };
    },
    SET_CONTACT_INFO_TO_STATE(state: any, payload: any) {
      state.contactInfo = payload[0];
    },
    SET_EMPLOYMENT_INFO_TO_STATE(state: any, payload: any) {
      state.employmentInfo = payload[0];
    },
    SET_FILTER_BYS_TO_STATE(state: any, payload: Array<string>) {
      const filterByObj: StateObject = {};
      filterByObj[payload[0]] = payload[1];

      // Convert state.filterBys array to a Map
      const filterByMap = new Map<string, StateObject>(
        state.filterBys.map((obj: StateObject) => [Object.keys(obj)[0], obj])
      );

      // Set the new filter object in the Map
      filterByMap.set(payload[0], filterByObj);

      // Convert the Map back to an array of objects
      state.filterBys = Array.from(filterByMap.values());
    },
    REMOVE_FILTER_BYS_FROM_STATE(state: any, payload: string) {
      const filterBysArr = [...state.filterBys];
      state.filterBys = filterBysArr.filter((obj: StateObject) => {
        return Object.keys(obj)?.includes(payload) === false;
      });
    },
    RESET_FILTER_BYS_FROM_STATE(state: any, payload: Array<string>) {
      state.filterBys = payload;
    },
    SET_USER_KEY_TO_STATE(state: any, payload: any) {
      state.userKey = payload;
    },
    SET_USER_INFO_TO_STATE(state: any, payload: any) {
      state.userInfo = payload;
    },
    SET_EDIT_EMPLOYEE_STATE(state: any, payload: any) {
      state.editEmployee = payload;
    },
    SET_SHOW_EDIT_BUTTON_TO_STATE(state: any, payload: any) {
      state.editButton = payload;
    },
    SET_GROUPED_MODULES_TO_STATE(state: any, payload: any) {
      state.groupedModules = payload;
    },
    SET_PUBLIC_HOLIDAYS_TO_STATE(state: any, payload: any) {
      state.publicHolidays = payload;
    },
    SET_LEAVE_ID_TO_STATE(state: any, payload: any) {
      state.leaveId = payload;
    },
    SET_LEAVE_MONTH_TO_STATE(state: any, payload: any) {
      state.month = payload;
    },
    SET_HOME_TO_STATE(state: any, payload: any) {
      state.home = payload;
    },
    SET_LEAVE_TYPE_TO_STATE(state: any, payload: any) {
      state.leaveType = payload;
    },
    SET_LEAVE_STATUS_TO_STATE(state: any, payload: any) {
      state.status = payload;
    },
    SET_LEAVE_PERSON_TO_STATE(state: any, payload: any) {
      state.requestedby = payload;
    },
    SET_LEAVE_REQUEST_NAME_TO_STATE(state: any, payload: any) {
      state.requestedbyHome = payload;
    },
    SET_CONTROLLERS_TO_STATE(state: any, payload: any) {
      state.controllers = payload;
    },
    SET_BANK_HEADERS_TO_STATE(state: any, payload: any) {
      state.bankHeaders = payload;
    },
    SET_BANK_DETAILS_TO_STATE(state: any, payload: any) {
      state.allBankDetails = payload;
    },
    SET_ABSENCE_TO_STATE(state: any, payload: any) {
      state.allAbsence = payload;
    },
    SET_ALL_EMPLOYEES_ABSENT_TO_STATE(state: any, payload: any) {
      state.allEmployeesAbsent = payload;
    },
    SET_ALL_ANNOUNCEMENTS_TO_STATE(state: any, payload: any) {
      state.allAnnouncements = payload;
    },
    SET_LEAVE_YEAR_TO_STATE(state: any, payload: any) {
      state.year = payload;
    },
    SET_LEAVE_MONTHHOME_TO_STATE(state: any, payload: any) {
      state.monthHome = payload;
    },
    SET_LEAVE_YEARHOME_TO_STATE(state: any, payload: any) {
      state.yearHome = payload;
    },
    SET_USER_DOCUMENTS_TO_STATE(state: any, payload: any) {
      state.userDocuments = payload;
    },
    SET_TEAM_TO_STATE(state: any, payload: any) {
      state.teams = payload;
    },
    SET_POSITIONS_TO_STATE(state: any, payload: any) {
      state.positions = payload;
    },
  },
  getters: {
    isProjectManagers(state: any) {
      return state.projectMangers;
    },
    isCurrentTab(state: any) {
      return state.tabId;
    },
    isAllEmployees(state: any) {
      return state.employees;
    },
    isEmployeeUserKeyFromHome(state: any) {
      return state.employee_user_key;
    },
    isAllControllers(state: any) {
      return state.controllers;
    },
    isAllJobHistories(state: any) {
      return state.jobHistories;
    },
    isPersonalData(state: any) {
      return state.personalData;
    },
    isEmployeeName(state: any) {
      return state.employeeName;
    },
    isUserKey(state: any) {
      return state.userKey;
    },
    isEditUserKey(state: any) {
      return state.editUserKey;
    },
    isFilters(state: any) {
      return [state.personalInfo, state.contactInfo, state.employmentInfo];
    },
    isTableHeaders(state: any) {
      return state.tableHeaders;
    },
    isAllQualification(state: any) {
      return state.qualifications;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isAbsenceTotalPages(state: any) {
      return state.absenceTotalPages;
    },
    isEditEmployee(state: any) {
      return state.editEmployee;
    },
    isEditButton(state: any) {
      return state.editButton;
    },
    isGroupedModules(state: any) {
      return state.groupedModules;
    },
    isFilterBys(state: any) {
      return state.filterBys;
    },
    isPublicHolidays(state: any) {
      return state.publicHolidays;
    },
    isLeaveId(state: any) {
      return state.leaveId;
    },
    isLeaveType(state: any) {
      return state.leaveType;
    },
    isStatus(state: any) {
      return state.status;
    },
    isMonth(state: any) {
      return state.month;
    },
    isYear(state: any) {
      return state.year;
    },
    isYearHome(state: any) {
      return state.yearHome;
    },
    isMonthHome(state: any) {
      return state.monthHome;
    },
    isApproved(state: any) {
      return state.approved;
    },
    isHome(state: any) {
      return state.home;
    },
    isPesonalLeaves(state: any) {
      return state.personalLeaves;
    },
    isEmployeeLeaves(state: any) {
      return state.employeeLeaves;
    },
    isAllLeaves(state: any) {
      return state.allLeaves;
    },
    isRequested(state: any) {
      return state.requestedLeaves;
    },
    isRequestedHome(state: any) {
      return state.requestedbyHome;
    },
    isRequestedPerson(state: any) {
      return state.requestedby;
    },
    isBankHeaders(state: any) {
      return state.bankHeaders;
    },
    isAllBankDetails(state: any) {
      return state.allBankDetails;
    },
    isAllAbsence(state: any) {
      return state.allAbsence;
    },
    isAllEmployeesAbsent(state: any) {
      return state.allEmployeesAbsent;
    },
    isEmployeesUpdate(state: any) {
      return state.isEmployeesUpdate;
    },
    isAllAnnouncements(state: any) {
      return state.allAnnouncements;
    },
    isUserDocuments(state: any) {
      return state.userDocuments;
    },
    isTeams(state: any) {
      return state.teams;
    },
    isPositions(state: any) {
      return state.positions;
    },
  },
  actions: {
    // searchUsers(){
    //   Api()
    //   .post('/hr/employees/search-employees/1/')
    //   .then((response:any) =>{
    //
    //   })
    // }
    getPersonalInfo(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${getEmployeeContactInfo}${payload}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_PERSONAl_DATA_TO_STATE", responseData);
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    getAllForeignEmployees(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${getAllForeignEmployees}${payload}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const data = response.data.data?.map((data: any) => {
              return {
                name: `${data.first_name} ${data.middle_name} ${data.last_name}`,
                title: data.title,
                country: data.country_of_birth,
                email: data.employee__email,
                user_key: data.employee__user_key,
                is_active: data.employee__is_active,
                profile_picture: data.profile_picture,
              };
            });

            context.commit("SET_EMPLOYEES_TO_STATE", data);

            context.commit(
              "SET_CURRENT_PAGE_TO_STATE",
              response.data.current_page
            );
            context.commit(
              "SET_TOTAL_PAGES_TO_STATE",
              response.data.total_data
            );
          }
        });
    },

    getAllEmployees(context: ActionContext<any, any>, payload: any) {
      const endpoint= payload ? `/hr/employees/get-all-employees/Employee/?access_type=${payload.param}` : `/hr/employees/get-all-employees/Employee/`
      Api()
        .get(endpoint)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_EMPLOYEES_TO_STATE", responseData.data);
          }
        });
    },
    getAllControllers(context: ActionContext<any, any>) {
      Api()
        .get("/hr/employees/get-offer-controllers/")
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_CONTROLLERS_TO_STATE", responseData.data);
          }
        });
    },
    getAllBankDetails(context: ActionContext<any, any>, payload: any) {
      Api()
        .post(`/configuration/projects/all-banks/${payload}/`)
        .then((response: any) => {
          if (response) {
            const responseData = response.data;
            context.commit("SET_BANK_DETAILS_TO_STATE", responseData.data);
          }
        });
    },
    getAllAbsence(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${getEmployeeAbsence}${payload}/`)
        .then((response: any) => {
          if (response) {
            const responseData = response.data;
            context.commit("SET_ABSENCE_TO_STATE", responseData);
          }
        });
    },
    getAllEmployeesAbsent(context: ActionContext<any, any>, payload: any) {
      Api()
        .post(`${getAllEmployeeAbsentByTeamLeader}`, payload)
        .then((response: any) => {
          if (response) {
            const responseData = response.data;
            context.commit("SET_ALL_EMPLOYEES_ABSENT_TO_STATE", responseData);
          }
        })
        .catch(() => {});
    },
    getAllAnnouncements(context: ActionContext<any, any>) {
      Api()
        .get(`${getMyAnnouncements}`)
        .then((response: any) => {
          if (response) {
            const responseData = response.data;
            context.commit("SET_ALL_ANNOUNCEMENTS_TO_STATE", responseData.data);
          }
        });
    },
    getEmployeeDetails(context: ActionContext<any, any>) {
      const userKey = context.getters.isUserKey;
      Api()
        .get(`/hr/employees/get-employee-personal-contact-info/${userKey}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_PERSONAl_DATA_TO_STATE", responseData?.data);
          }
          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getAnnounceTeam(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${teamAnnouncement}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_TEAM_TO_STATE", responseData.data);
          }
        });
    },
    getAnnouncePosition(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${positionAnnouncement}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_POSITIONS_TO_STATE", responseData.data);
          }
        });
    },
    getAllProjectManagers(context: ActionContext<any, any>) {
      Api()
        .get("/projects/get-project-admins/")
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_PROJECT_MANAGERS_TO_STATE", responseData.data);
          }
        });
    },
  },
};
