// import Api from "@/services/api"

import api from "@/services/api";
import { ActionContext } from "vuex";

interface StateObject {
  [key: string]: any; // Define an index signature
}

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    totalPages: null,
    totalData: null,
    partners: [],
    qualifications: [],
    personalData: null,
    userKey: null,
    tabId: 0,
    partnerId: null,
    personalInfo: null,
    editPartner: false,
    editButton: false,
    filterBys: [],
    isPartnerUpdate: false,
  },

  mutations: {
    SET_PARTNERS_TO_STATE(state: any, payload: any) {
      state.partners = payload;
    },
    SET_EDIT_PARTNER_TO_STATE(state: any, payload: any) {
      state.editPartner = payload;
    },
    SET_EDIT_BUTTON_TO_STATE(state: any, payload: any) {
      state.editButton = payload;
    },
    SET_TABLE_HEADERS_TO_STATE(state: any, payload: any) {
      state.tableHeaders = payload;
    },
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_TAB_TO_STATE(state: any, payload: any) {
      state.tabId = payload;
    },
    SET_PERSONAl_DATA_TO_STATE(state: any, payload: any) {
      state.personalData = payload;
    },
    SET_PERSONAl_INFO_TO_STATE(state: any, payload: any) {
      state.personalInfo = payload;
    },
    SET_PARTNER_KEY_TO_STATE(state: any, payload: any) {
      state.partnerId = payload;
    },
    SET_PARTNER_UPDATE(state: any, payload: any) {
      state.isPartnerUpdate = payload;
    },
    SET_FILTER_BYS_TO_STATE(state: any, payload: Array<string>) {
      const filterByObj: StateObject = {};
      filterByObj[payload[0]] = payload[1];

      // Convert state.filterBys array to a Map
      const filterByMap = new Map<string, StateObject>(
        state.filterBys.map((obj: StateObject) => [Object.keys(obj)[0], obj])
      );

      // Set the new filter object in the Map
      filterByMap.set(payload[0], filterByObj);

      // Convert the Map back to an array of objects
      state.filterBys = Array.from(filterByMap.values());
    },
    REMOVE_FILTER_BYS_FROM_STATE(state: any, payload: string) {
      const filterBysArr = [...state.filterBys];
      state.filterBys = filterBysArr.filter((obj: StateObject) => {
        return Object.keys(obj)?.includes(payload) === false;
      });
    },
  },
  getters: {
    isCurrentTab(state: any) {
      return state.tabId;
    },
    isEditPartner(state: any) {
      return state.editPartner;
    },
    isEditButton(state: any) {
      return state.editButton;
    },
    isFilterBys(state: any) {
      return state.filterBys;
    },
    isAllPartners(state: any) {
      return state.partners;
    },
    isPartnerInfo(state: any) {
      return state.personalData;
    },
    isFilters(state: any) {
      return state.personalInfo;
    },
    isTableHeaders(state: any) {
      return state.tableHeaders;
    },
    isAllQualification(state: any) {
      return state.qualifications;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isPartnerId(state: any) {
      return state.partnerId;
    },
    isPartnerUpdate(state: any) {
      return state.isPartnerUpdate;
    },
  },
  actions: {
    // searchUsers(){
    //   Api()
    //   .post('/hr/experts/search-experts/1/')
    //   .then((response:any) =>{
    //   })
    // }
    getAllPartners(context: ActionContext<any, any>) {
      api()
        .post(`/entities/get-partners-and-clients/`, { data_type: "Partner" })
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data;
            context.commit("SET_PARTNERS_TO_STATE", responseData.data);
          }
        });
    },
  },
};
