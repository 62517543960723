import Api from "@/services/api";
import { getEntitySubsidiaries } from "@/services/endpoints";

export default {
  namespaced: true,
  state: {
    entity_subsidiaries: [],
    editEntityId: null,
    deleteEntityId: null,
    editEntityItem: null,
    singleEntitySubsidiaries: [],
    singleEntityProjects: [],
    singleEntityRecentChanges: [],
    allKeywords: [],
    originalSingleEntityData: {},
    currentEditEntityTab: "Entity Basic Information",
    advanceEntitySearchResults: {},
  },
  getters: {
    getSubsidiaries: (state: any) => state.entity_subsidiaries,
    getSubsidiariesDropdown: (state: any) =>
      state.entity_subsidiaries.map((subsidiary: any) => ({
        value: subsidiary.id,
        text: subsidiary.name,
        status: subsidiary.status,
      })),
    getEditEntityId: (state: any) => state.editEntityId,
    getDeleteEntityId: (state: any) => state.deleteEntityId,
    getEditEntityItem: (state: any) => state.editEntityItem,
    getSingleEntitySubsidiaries: (state: any) =>
      state.singleEntitySubsidiaries.slice(),
    getSingleEntityProjects: (state: any) => state.singleEntityProjects.slice(),
    getSingleEntityRecentChanges: (state: any) =>
      state.singleEntityRecentChanges.slice(),
    getAllKeywords: (state: any) => state.allKeywords.slice(),
    getCurrentEditEntityTab: (state: any) => state.currentEditEntityTab,
    getAdvanceEntitySearchResults: (state: any) =>
      state.advanceEntitySearchResults,
  },
  mutations: {
    setEntitySubsidiaries: (state: any, subsidiaries: any) =>
      (state.entity_subsidiaries = subsidiaries),
    setEditEntityId: (state: any, entityId: any) =>
      (state.editEntityId = entityId),
    setDeleteEntityId: (state: any, entityId: any) =>
      (state.deleteEntityId = entityId),
    setEditEntityItem: (state: any, entityItem: any) =>
      (state.editEntityItem = entityItem),
    setSingleEntitySubsidiaries: (state: any, subsidiaries: any) =>
      (state.singleEntitySubsidiaries = subsidiaries),
    setSingleEntityProjects: (state: any, projects: any) =>
      (state.singleEntityProjects = projects),
    setSingleEntityRecentChanges: (state: any, changes: any) =>
      (state.singleEntityRecentChanges = changes),
    setAllKeywords: (state: any, keywords: any) =>
      (state.allKeywords = keywords),
    setOriginalEntityData: (state: any, payload: any) =>
      (state.originalSingleEntityData = payload),
    setCurrentEditEntityTab: (state: any, tab: any) =>
      (state.currentEditEntityTab = tab),
    setAdvanceEntitySearchResults: (state: any, results: any) =>
      (state.advanceEntitySearchResults = results),
  },
  actions: {
    getAllSubsidiaries: async ({ commit }: any) => {
      try {
        const response = await Api().get(getEntitySubsidiaries);
        commit("setEntitySubsidiaries", response.data.data);
      } catch (error) {
        // console.error(error);
      }
    },
    setEditItemToState: ({ commit }: any, payload: any) => {
      const newObj = payload;
      Object.keys(payload).forEach((key) => {
        if (key === "business_areas" || key === "entity_type") {
          newObj[key] = newObj[key].map((ele: any) => {
            return {
              text: ele,
              value: ele,
            };
          });
        }
      });
      commit("setEditEntityItem", newObj);
    },
  },
};
