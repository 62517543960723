<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "SendEmail"
})
</script>

<template>
  <svg class="icon icon-tabler icons-tabler-outline icon-tabler-mail-share" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
       stroke-width="2" viewBox="0 0 24 24" width="24"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none" stroke="none"/>
    <path d="M13 19h-8a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6"/>
    <path d="M3 7l9 6l9 -6"/>
    <path d="M16 22l5 -5"/>
    <path d="M21 21.5v-4.5h-4.5"/>
  </svg>
</template>

<style scoped>

</style>