<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppSidebarNavLinkItem",
  emits: ["toggle-submodules"],
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subModules: {
      type: Array,
      default: () => [],
    },
    link: {
      type: String,
      default: "",
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubmodulesOpen: false,
      hovered: false,
      hoveredItemTop: 0,
    };
  },
  computed: {
    showTooltip() {
      return this.hovered && this.title.length > 20;
    },
    isRail() {
      return this.$store.getters["auth/isRail"];
    },
    hasSubModules() {
      return this.subModules.length > 0;
    },
    navItemStyles() {
      return "h-12 w-full max-w-[295px] rounded-lg flex items-center gap-5 p-2 cursor-pointer mt-2 z-20 text-gray-700 hover:bg-gray-100 transition-bg duration-700 ease-in-out transform hover:scale-105";
    },
    currentPath() {
      return this.$route.path;
    },
    isSubmoduleActive() {
      const submoduleRoutes = this.subModules?.map((submodule) => submodule[1]);
      return submoduleRoutes.includes(this.currentPath);
    },
  },
  methods: {
    navigateTo() {
      this.$router.push({ path: this.link });
    },
    truncateTitle(text) {
      return text.length > 20 ? text.substring(0, 20) + "..." : text;
    },
    isTruncated() {
      return this.title.length > 20;
    },
    handleNavLinkItemClick() {
      if (this.hasSubModules) {
        this.$emit("toggle-submodules");
      } else {
        this.navigateTo();
      }
    },
  },
  mounted() {},
  watch: {},
});
</script>

<template>
  <div class="relative">
    <!--  tooltip-->
    <div
      v-if="showTooltip"
      :class="[
        'absolute w-max text-xs bg-white px-3 py-2 rounded-lg shadow-md z-[1000]',
        isLast ? 'bottom-full' : 'top-full',
      ]"
    >
      <div
        :class="[
          'w-3 h-3 bg-white absolute mx-auto rotate-45 z-0',
          isLast ? '-bottom-1 left-0 right-0' : 'inset-0',
        ]"
      ></div>
      {{ $t(title.getTranslationKey()) }}
    </div>
    <!--  tooltip-->
    <div
      :data-element="hasSubModules ? 'nav-list' : 'nav-item'"
      :class="[
        navItemStyles,
        { active: hasSubModules ? isSubmoduleActive : currentPath === link },
      ]"
      id="sidebar-nav-link-item"
      @click="handleNavLinkItemClick"
      ref="navItem"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    >
      <v-icon :icon="icon" class="w-6 h-6 inactive"></v-icon>
      <span class="font-medium">
        <slot name="itemName">
          <span class="text-no-wrap">{{
            truncateTitle($t(title.getTranslationKey()))
          }}</span>
        </slot>
      </span>
      <v-icon
        v-if="hasSubModules"
        icon="mdi-chevron-down"
        :class="[
          'w-6 h-6 ml-auto transition-rotate duration-500',
          isSubmodulesOpen ? 'rotate-180' : '',
        ]"
      ></v-icon>
    </div>
    <template v-if="hasSubModules && isSubmodulesOpen">
      <div class="">
        <div
          id="submodules"
          v-for="subModule in subModules"
          :key="subModule.id"
        >
          <app-sidebar-nav-link-item
            icon=""
            :title="subModule[0]"
            :link="subModule[1]"
            :is-last="isLast"
          >
            <span class="text-no-wrap">{{
              truncateTitle($t(subModule.name.getTranslationKey()))
            }}</span>
          </app-sidebar-nav-link-item>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
.active {
  background-color: #fbf7f6;
  color: #8b2923;
}

.active:hover {
  background-color: #fbf7f6;
  color: #8b2923;
}
</style>
