import Api from "@/services/api";
import { ActionContext } from "vuex";
import {
  getAllEmployeesNonPaginated,
  getEmployeeSignature,
  getOpenInvoices,
  getVatRatesUnpaginated,
  getEntertainmentAccountNumbersUnpaginated,
  getAccountNumbersUnpaginated,
  filterCostCenterCompanyAndBusiness,
  getBudgetItems,
  getAllTravellingExpenses,
  getEmployeeAdvancePayment,
  getAllCountriesNonPaginated,
  getAllCvTemplatesGenerated,
  getAllEntitiesWithFilter,
  getUserProject,
  getAllCostCenters as getAllCostCentersEndpoint,
} from "@/services/endpoints";
import countries from "@/services/country_api";
import currencies from "@/services/currencies_api";

interface EntityPayload {
  filter_query: { [key: string]: string };
  response: string[];
}

export default {
  namespaced: true,
  state: {
    appLoading: false,
    currentPage: 1,
    totalPages: null,
    totalData: null,
    tabId: 0,
    allEmployees: [],
    allExperts: [],
    allCostCenters: [],
    AllLanguages: [],
    allContracts: [],
    allBudgetItems: [],
    allCountries: [],
    allEntertainmentAccountNumbers: [],
    allVatRates: [],
    allAccountNumbers: [],
    signatureImageUrl: "",
    signatureDate: null,
    documentUrl: [],
    pictureUrl: [],
    userKey: null,
    openInvoices: [],
    allCostCenterCompanyAndBusiness: [],
    addAllToArray: { text: "All", value: "All" },
    budgetItems: [],
    allTravellingExpenses: [],
    allEmployeeAdvancePayment: [],
    countriesWithRegions: [],
    entities: [],
    keywords: [],
    userProjects: [],
    allGeneratedCvTemplates: [],
  },

  mutations: {
    SET_CURRENT_PAGE_TO_STATE(state: any, payload: any) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGES_TO_STATE(state: any, payload: any) {
      state.totalPages = payload;
    },
    SET_CURRENT_TAB_TO_STATE(state: any, payload: any) {
      state.tabId = payload;
    },
    SET_SHARED_RESOURCES_TO_STATE(state: any, payload: any) {
      state.allEmployees = payload.data;
      state.totalPages = payload.meta.total_pages;
      state.totalData = payload.meta.total;
      state.currentPage = payload.meta.current_page;
    },
    SET_SIGNATURE_IMAGE_URL_TO_STATE(state: any, payload: any) {
      state.signatureImageUrl = payload;
    },
    SET_USER_KEY_TO_STATE(state: any, payload: any) {
      state.userKey = payload;
    },
    SET_SIGNATURE_DATE_TO_STATE(state: any, payload: any) {
      state.signatureDate = payload;
    },
    SET_OPEN_INVOICES_TO_STATE(state: any, payload: any) {
      state.openInvoices = payload;
    },
    SET_DOCUMENT_URL_TO_STATE(state: any, payload: any) {
      state.documentUrl = payload;
    },
    SET_PICTURE_URL_TO_STATE(state: any, payload: any) {
      state.pictureUrl = payload;
    },
    SET_ALL_ACCOUNT_NUMBERS_TO_STATE(state: any, payload: any) {
      state.allAccountNumbers = payload;
    },
    SET_ALL_VAT_RATES_TO_STATE(state: any, payload: any) {
      state.allVatRates = payload;
    },
    SET_ALL_ENTERTAINMENT_ACCOUNT_NUMBERS_TO_STATE(state: any, payload: any) {
      state.allEntertainmentAccountNumbers = payload;
    },
    SET_COST_CENTER_COMPANY_AND_BUSINESS_TO_STATE(state: any, payload: any) {
      state.allCostCenterCompanyAndBusiness = payload;
    },
    SET_BUDGET_ITEM_TO_STATE(state: any, payload: any) {
      state.budgetItems = payload;
    },
    SET_ALL_TRAVELLING_EXPENSES_TO_STATE(state: any, payload: any) {
      state.allTravellingExpenses = payload;
    },
    SET_APP_LOADING_TO_STATE(state: any, payload: any) {
      state.appLoading = payload;
    },
    SET_EMPLOYEE_ADVANCE_PAYMENT_TO_STATE(state: any, payload: any) {
      state.allEmployeeAdvancePayment = payload;
    },
    SET_COUNTRIES_WITH_REGION(state: any, payload: any) {
      state.countriesWithRegions = payload;
    },
    SET_ENTITIES_TO_STATE(state: any, payload: any) {
      state.entities = payload;
    },
    SET_KEYWORDS(state: any, payload: any) {
      state.keywords = payload;
    },
    SET_USER_PROJECTS(state: any, payload: any) {
      state.userProjects = payload;
    },
    SET_ALL_COST_CENTERS(state: any, payload: any) {
      state.allCostCenters = payload;
    },
    SET_ALL_CV_TEMPLATES_GENERATED(state: any, payload: any) {
      state.allGeneratedCvTemplates = payload;
    },
  },
  getters: {
    allCostCenters(state: any) {
      return state.allCostCenters;
    },
    costCenterDropdownOptions(state: any) {
      return state.allCostCenters.map((costCenter: any) => {
        return {
          text: costCenter?.cost_center_full_number + "-" + costCenter?.title,
          value: costCenter.id,
        };
      });
    },
    appLoading(state: any) {
      return state.appLoading;
    },
    isCurrentTab(state: any) {
      return state.tabId;
    },
    isCurrentPage(state: any) {
      return state.currentPage;
    },
    isTotalPages(state: any) {
      return state.totalPages;
    },
    isSettlementStatusOptions(state: any) {
      return state.settlementStatusOptions;
    },
    isTotalData(state: any) {
      return state.totalData;
    },
    signatureImageUrl(state: any) {
      return state.signatureImageUrl;
    },
    signatureDate(state: any) {
      return state.signatureDate;
    },

    openInvoices(state: any) {
      return state.openInvoices.cost_centres;
    },
    documentUrl(state: any) {
      return state.documentUrl;
    },
    pictureUrl(state: any) {
      return state.pictureUrl;
    },
    hasOpenInvoices(state: any) {
      return state.openInvoices.length > 0;
    },
    isCountriesWithRegion(state: any) {
      return state.countriesWithRegions;
    },
    getCountriesDropdownList(state: any, _: any, __: any, rootGetters: any) {
      const appLocale = rootGetters["translation/getLocale"];
      return state.countriesWithRegions.map((country: any) => ({
        id: country.id,
        text: country[`country_${appLocale}`],
        value: country[`country_${appLocale}`],
      }));
    },
    isAllEntities(state: any) {
      return state.entities;
    },
    getAllAccountNumbers(state: any) {
      return state.allAccountNumbers?.map((account: any) => {
        return {
          text: account.display_name,
          value: account.id,
        };
      });
    },
    getAllVatRates(state: any) {
      return state.allVatRates?.map((data: any) => {
        return {
          rate: data.rate,
          text: `${data.name} (${data.rate})`,
          value: data.id,
        };
      });
    },
    getAllEntertainmentAccountNumbers(state: any) {
      return state.allEntertainmentAccountNumbers;
    },
    getCountryByName() {
      return countries?.map((country: any) => {
        return {
          text: country.name,
          value: country.name,
        };
      });
    },
    getBusinessAreas(state: any) {
      const data = state.allCostCenterCompanyAndBusiness.business_areas?.map(
        (costCenter: any) => {
          return {
            text: costCenter.title === null ? "" : costCenter.title,
            value: costCenter.id,
          };
        }
      );
      return [state.addAllToArray, ...data];
    },
    getCompanies(state: any) {
      // Use a Set to keep track of unique ids
      const arrayData = state.allCostCenterCompanyAndBusiness.companies;

      const data = arrayData?.map((company: any) => {
        return {
          text: company.title,
          value: company.id,
        };
      });
      return [state.addAllToArray, ...data];
    },
    getCompaniesWithoutAll(state: any) {
      // Use a Set to keep track of unique ids
      const arrayData = state.allCostCenterCompanyAndBusiness.companies;

      const data = arrayData?.map((company: any) => {
        return {
          text: company.title,
          value: company.id,
        };
      });
      return data;
    },
    getCostCenters(state: any) {
      return state.allCostCenterCompanyAndBusiness.cost_centers?.map(
        (costCenter: any) => {
          return {
            text:
              costCenter.cost_centre__project_title === null
                ? costCenter.cost_center_number
                : `${costCenter.cost_center_number} - ${costCenter.cost_centre__project_title}`,
            value: costCenter.id,
          };
        }
      );
    },
    getCurrenciesBySymbol() {
      return currencies?.map((currencies) => {
        return {
          text: `${currencies?.name} (${currencies?.symbol})`,
          value: currencies?.symbol,
        };
      });
    },
    getCurrenciesByName() {
      return currencies?.map((currencies) => {
        return {
          text: `${currencies?.name} (${currencies?.symbol})`,
          value: currencies?.name,
        };
      });
    },
    getCurrencyByCode() {
      return currencies?.map((currencies) => {
        return {
          text: `${currencies?.name} (${currencies?.code})`,
          value: currencies?.code,
        };
      });
    },
    budgetItems(state: any) {
      return state.budgetItems?.map((budgetItem: any) => {
        return {
          text: budgetItem?.budget_item_position,
          value: budgetItem?.budget_item_id,
        };
      });
    },
    travellingExpenses(state: any) {
      return state.allTravellingExpenses;
    },
    employeeAdvancePayment(state: any) {
      return state.allEmployeeAdvancePayment?.map((payment: any) => {
        return {
          text: `${
            payment.cost_center
          } - ${payment.request_date?.formattedDate()} - ${payment.requested_amount?.getCurrencyFormat()}`,
          value: payment.id,
        };
      });
    },
    getAllKeywords(state: any) {
      return state?.keywords || [];
    },
    getKeywordsDropdownList(state: any) {
      return (
        state?.keywords.map((keyword: any) => ({
          id: keyword.id,
          text: keyword.title,
          value: keyword.title,
        })) || []
      );
    },
    getUserProjects(state: any) {
      return state.userProjects?.slice() || [];
    },
    getUserProjectsCount(state: any) {
      return state.userProjects?.length || 0;
    },
    allEmployees(state: any) {
      return state.allEmployees;
    },
    getAllGeneratedCvTemplates(state: any) {
      return state.allGeneratedCvTemplates;
    },
  },
  actions: {
    // getAllEmployeesNonPaginated
    getSharedResources(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(`${getAllEmployeesNonPaginated}${payload.employee_or_expert}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_SHARED_RESOURCES_TO_STATE", response.data);
          }
        })
        .catch((error: unknown) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", false);
          }
        });
    },
    getSignature(context: ActionContext<any, any>) {
      Api()
        .get(`${getEmployeeSignature}${context.state.userKey}/`)
        .then((response) => {
          if (response.data.status === "success") {
            const imageUrl = response.data.data.signature;
            context.commit("SET_SIGNATURE_IMAGE_URL_TO_STATE", imageUrl);
          }
        });
    },
    getOpenInvoices(context: ActionContext<any, any>) {
      Api()
        .get(`${getOpenInvoices}`)
        .then((response) => {
          if (response.data.status === "success") {
            const invoices = response.data.data;
            context.commit("SET_OPEN_INVOICES_TO_STATE", invoices);
          }
        });
    },
    getEntertainmentAccountNumbersUnpaginated(
      context: ActionContext<any, any>
    ) {
      Api()
        .get(`${getEntertainmentAccountNumbersUnpaginated}`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_ALL_ENTERTAINMENT_ACCOUNT_NUMBERS_TO_STATE(",
              response.data.data
            );
          }
        });
    },
    getVatRatesUnpaginated(context: ActionContext<any, any>) {
      Api()
        .get(`${getVatRatesUnpaginated}`)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit("SET_ALL_VAT_RATES_TO_STATE", response.data.data);
          }
        });
    },
    getAccountNumbersUnpaginated(context: ActionContext<any, any>) {
      Api()
        .get(`${getAccountNumbersUnpaginated}`)
        .then((response) => {
          if (response.data.status === "success") {
            const invoices = response.data.data;
            context.commit("SET_ALL_ACCOUNT_NUMBERS_TO_STATE", invoices);
          }
        });
    },
    filterCostCenterCompanyAndBusiness(
      context: ActionContext<any, any>,
      payload: any
    ) {
      const endpoint = payload
        ? `${filterCostCenterCompanyAndBusiness}?${payload.type}=${payload.value}`
        : `${filterCostCenterCompanyAndBusiness}`;
      Api()
        .get(endpoint)
        .then((response) => {
          if (response.data.status === "success") {
            context.commit(
              "SET_COST_CENTER_COMPANY_AND_BUSINESS_TO_STATE",
              response.data.data
            );
          }
        });
    },
    getBudgetItems(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${getBudgetItems}${payload}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_BUDGET_ITEM_TO_STATE", response.data.data);
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", true);
          }
        });
    },
    getAllTravellingExpenses(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING_TO_STATE", true);
      }
      Api()
        .get(
          `${getAllTravellingExpenses}?search=${payload.search || ""}&year=${
            payload.year || ""
          }`
        )
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_ALL_TRAVELLING_EXPENSES_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", true);
          }
        });
    },
    getEmployeeAdvancePayment(context: ActionContext<any, any>, payload: any) {
      Api()
        .get(`${getEmployeeAdvancePayment}${payload.id}/`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING_TO_STATE", false);
            context.commit(
              "SET_EMPLOYEE_ADVANCE_PAYMENT_TO_STATE",
              response.data.data
            );
          }
        })
        .catch((error: any) => {
          if (error) {
            context.commit("SET_APP_LOADING_TO_STATE", true);
          }
        });
    },
    getCountriesWithRegion(context: ActionContext<any, any>) {
      Api()
        .get(getAllCountriesNonPaginated)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_COUNTRIES_WITH_REGION", response.data.data);
          }
        })
        .catch();
    },
    getAllEntities(context: ActionContext<any, any>, payload: EntityPayload) {
      Api()
        .post(getAllEntitiesWithFilter, payload)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ENTITIES_TO_STATE", response.data.data);
          }
        })
        .catch();
    },
    getAllKeywords(context: any) {
      Api()
        .get("/projects/keywords/manage-keywords/")
        .then((res) => {
          const sortedKeywords = res.data?.data.sort(
            (a: any, b: any) => a.title > b.title
          );
          context.commit("SET_KEYWORDS", sortedKeywords);
        })
        .catch(() => {});
    },
    getAllUserProjects(context: any) {
      Api()
        .get(getUserProject)
        .then((response: any) => {
          if (response.data.status === "success") {
            const responseData = response.data.data;
            context.commit("SET_USER_PROJECTS", responseData);
          }
        })
        .catch(() => {});
    },
    getAllCostCenters(context: ActionContext<any, any>, page: any) {
      Api()
        .get(getAllCostCentersEndpoint)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_ALL_COST_CENTERS", response.data?.data);
          }
        })
        .catch(() => {
          // console.log(error)
        });
    },
    fetchAllCvTemplates(context: ActionContext<any, any>, payload: any) {
      if (payload.load_page) {
        context.commit("SET_APP_LOADING", true);
      }
      Api()
        .get(`${getAllCvTemplatesGenerated}`)
        .then((response: any) => {
          if (response.data.status === "success") {
            context.commit("SET_APP_LOADING", false);
            context.commit(
              "SET_ALL_CV_TEMPLATES_GENERATED",
              response.data.data
            );
          }
        })
        .catch((error: any) => {
          if (error.response) {
            context.commit("SET_APP_LOADING", false);
          }
        });
    },
  },
};
